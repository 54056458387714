import React from 'react';
import { LuEye } from "react-icons/lu";
import { HiOutlineSpeakerphone } from "react-icons/hi";

function Footer() {
  return (
    <><section className="border-y border-gray-700 ">
      <div className="mx-auto md:mx-auto md:w-[1440px] px-10 md:flex">
        <div className="px-2 py-6 break-normal content-center space-y-6 md:pr-14 md:py-12 border-0 border-black md:w-1/2 md:border-gray-700 border-r md:space-y-6">
          <LuEye className="size-12 text-white" />
          <h2 className="text-3xl font-bold text-primary">Gostou do que viu?</h2>
          <p className="text-lg text-white break-normal">
            Se você chegou até aqui, por que não nos envia uma mensagem?
            </p>
            <p className="text-lg text-white break-normal">
            Estamos aguardando teu contato, então não perca essa oportunidade!
          </p>
          <button><a href="https://api.whatsapp.com/send?phone=5513996687799&text=Ol%C3%A1%2C%20gostaria%20de%20um%20or%C3%A7amento%20para%20um%20site!" className="bg-primary text-center hover:bg-primary/90 rounded-full text-white font-bold py-2 px-4">
            Entre em Contato
          </a></button>
        </div>

         <div className="p-4 py-0 pr-0 border-0 border-black md:w-1/2 grid gap-4 md:py-24 md:pl-24">
          {/* <HiOutlineSpeakerphone className="size-12 text-white" />
          <h2 className="text-3xl font-bold text-primary">Prefere por e-mail?</h2>
          <Form /> */}
        </div> */

      </div>
    </section>
    <div className="mx-auto text-center md:text-left max-w-[1440px] py-4 px-10">
        <p className="text-sm md:font-light text-white/70">©2024 RafaelGuilherme. All Rights Reserved.</p>
    </div>
    <a href="https://api.whatsapp.com/send?phone=5513996687799&text=Ol%C3%A1%2C%20gostaria%20de%20um%20or%C3%A7amento%20para%20um%20site!" target="_blank" rel="noreferrer noopener" class="fixed bottom-4 right-4 z-50 inline-flex items-center justify-center w-14 h-14 rounded-full bg-[#25d366]">
      <div className="absolute z-10 top-0 left-0 w-full h-full rounded-full bg-[#25d366] animate-ping"></div>
      <div className="relative z-20">
        <svg fill="#fff" height="24px" width="24px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308.00 308.00" stroke="#fff" transform="matrix(1, 0, 0, 1, 0, 0)">
              <path d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"/> 
              <path d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"/>
        </svg>
      </div>
    </a>
  </>
  );
}

export default Footer;